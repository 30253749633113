// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useCallback, useState } from 'react';
import { Col } from 'antd/lib/grid';
import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import Select from 'antd/lib/select';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';

import { FilterIcon, FullscreenIcon, GuideIcon } from 'icons';
import config from 'config';
import {
    DimensionType, Job, JobStage, JobState,
} from 'cvat-core-wrapper';
import { Workspace } from 'reducers';

import MDEditor from '@uiw/react-md-editor';
import { Radio } from 'antd';

interface Props {
    showStatistics(): void;
    showFilters(): void;
    changeWorkspace(workspace: Workspace): void;
    jobInstance: Job;
    frameNumber: number;
    startFrame:number;
    stopFrame:number;
    goFrameByFrameNumber(frameNumber: number): void;
    workspace: Workspace;
    annotationFilters: object[];
    initialOpenGuide: boolean;
}

function RightGroup(props: Props): JSX.Element {
    const {
        showStatistics,
        changeWorkspace,
        showFilters,
        goFrameByFrameNumber,
        workspace,
        frameNumber,
        jobInstance,
        annotationFilters,
        initialOpenGuide,
    } = props;

    const filters = annotationFilters.length;

    const openGuide = useCallback(() => {
        const PADDING = Math.min(window.screen.availHeight, window.screen.availWidth) * 0.4;
        jobInstance.guide().then((guide) => {
            if (guide) {
                Modal.info({
                    icon: null,
                    width: window.screen.availWidth - PADDING,
                    className: 'cvat-annotation-view-markdown-guide-modal',
                    content: (
                        <MDEditor
                            visibleDragbar={false}
                            data-color-mode='light'
                            height={window.screen.availHeight - PADDING}
                            preview='preview'
                            hideToolbar
                            value={guide.markdown}
                        />
                    ),
                });
            }
        }).catch((error: unknown) => {
            notification.error({
                message: 'Could not receive annotation guide',
                description: error instanceof Error ? error.message : console.error('error'),
            });
        });
    }, [jobInstance]);

    const [btnState, setBtnState] = useState<any>({
        pre: false,
        next: false,
    });

    const findHasMissedFrameNumber = (value: number, currentNumber:number): Promise<number> => {
        return new Promise((resolve) => {
            jobInstance.frames.frameNumbers().then(async (framesNumbers) => {
                const currentIndex = framesNumbers.findIndex((item) => item === currentNumber);
                for (let i = currentIndex + value; i <= framesNumbers.length; value > 0 ? i++ : i--) {
                    if (framesNumbers[i] === undefined) {
                        resolve(-1);
                        break;
                    }
                    const annotations = await jobInstance.annotations.get(framesNumbers[i], true, []);
                    const miss = annotations.find((item) => item.label.name.endsWith('-Missed'));
                    if (miss) {
                        resolve(framesNumbers[i]);
                        break;
                    }
                }
            });
        });
    };
    const findMissedFrame = async (value:number) => {
        const nextNumber = await findHasMissedFrameNumber(value, frameNumber);
        goFrameByFrameNumber(nextNumber);
        // changeBtnState(value, nextNumber);
    };

    useEffect(() => {
        if (Number.isInteger(jobInstance?.guideId)) {
            if (initialOpenGuide) {
                openGuide();
            } else if (
                jobInstance?.stage === JobStage.ANNOTATION &&
                jobInstance?.state === JobState.NEW
            ) {
                let seenGuides = [];
                try {
                    seenGuides = JSON.parse(localStorage.getItem('seenGuides') || '[]');
                    if (!Array.isArray(seenGuides) || seenGuides.some((el) => !Number.isInteger(el))) {
                        throw new Error('Wrong structure stored in local storage');
                    }
                } catch (error: unknown) {
                    seenGuides = [];
                }

                if (!seenGuides.includes(jobInstance.guideId)) {
                    // open guide if the user have not seen it yet
                    openGuide();
                    const updatedSeenGuides = Array
                        .from(new Set([
                            jobInstance.guideId,
                            ...seenGuides.slice(0, config.LOCAL_STORAGE_SEEN_GUIDES_MEMORY_LIMIT - 1),
                        ]));
                    localStorage.setItem('seenGuides', JSON.stringify(updatedSeenGuides));
                }
            }
        }
    }, []);

    useEffect(() => {
        findHasMissedFrameNumber(-1, frameNumber).then((preRes) => {
            findHasMissedFrameNumber(1, frameNumber).then((nextRes) => {
                setBtnState({ pre: preRes === -1, next: nextRes === -1 });
            });
        });
    }, [frameNumber]);

    return (
        <Col className='cvat-annotation-header-right-group'>
            <Radio.Group style={{ marginRight: 20 }}>
                <Radio.Button disabled={btnState.pre} onClick={() => findMissedFrame(-1)} value={-1}>
                    pre
                </Radio.Button>
                <Radio.Button disabled={btnState.next} onClick={() => findMissedFrame(1)} value={1}>
                    next
                </Radio.Button>
            </Radio.Group>
            <Button
                type='link'
                className='cvat-annotation-header-fullscreen-button cvat-annotation-header-button'
                onClick={(): void => {
                    if (window.document.fullscreenEnabled) {
                        if (window.document.fullscreenElement) {
                            window.document.exitFullscreen();
                        } else {
                            window.document.documentElement.requestFullscreen();
                        }
                    }
                }}
            >
                <Icon component={FullscreenIcon} />
                Fullscreen
            </Button>
            { jobInstance.guideId !== null && (
                <Button
                    type='link'
                    className='cvat-annotation-header-guide-button cvat-annotation-header-button'
                    onClick={openGuide}
                >
                    <Icon component={GuideIcon} />
                    Guide
                </Button>
            )}
            <Button
                type='link'
                className='cvat-annotation-header-info-button cvat-annotation-header-button'
                onClick={showStatistics}
            >
                <InfoCircleOutlined />
                Info
            </Button>
            <Button
                type='link'
                className={`cvat-annotation-header-filters-button cvat-annotation-header-button ${filters ?
                    'filters-armed' : ''
                }`}
                onClick={showFilters}
            >
                <Icon component={FilterIcon} />
                Filters
            </Button>
            <div>
                <Select
                    popupClassName='cvat-workspace-selector-dropdown'
                    className='cvat-workspace-selector'
                    onChange={changeWorkspace}
                    value={workspace}
                >
                    {Object.values(Workspace).map((ws) => {
                        if (jobInstance.dimension === DimensionType.DIMENSION_3D) {
                            if (ws === Workspace.STANDARD) {
                                return null;
                            }
                            return (
                                <Select.Option disabled={ws !== Workspace.STANDARD3D} key={ws} value={ws}>
                                    {ws}
                                </Select.Option>
                            );
                        }
                        if (ws !== Workspace.STANDARD3D) {
                            return (
                                <Select.Option key={ws} value={ws}>
                                    {ws}
                                </Select.Option>
                            );
                        }
                        return null;
                    })}
                </Select>
            </div>
        </Col>
    );
}

export default React.memo(RightGroup);
